var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.substitute(_vm.widgetData.name)) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "editor",
        fn: function () {
          return [
            _c(
              "div",
              {
                class: { mobile: _vm.isTouchScreen },
                attrs: { id: `LAUNCHPAD_WIDGET_BTN_ADD-${_vm.widget.uuId}` },
                on: { click: _vm.onEdit },
              },
              [_c("i", { staticClass: "far fa-pen-to-square" })]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: `LAUNCHPAD_WIDGET_BTN_ADD-${_vm.widget.uuId}`,
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "launchpad-container" }, [
                    _vm.widgetData.image
                      ? _c("div", {
                          staticClass: "launchpad-header",
                          style: {
                            "background-image": `url(${_vm.bannerUrl})`,
                          },
                        })
                      : _vm._e(),
                    _vm.widgetData.description
                      ? _c("div", { staticClass: "launchpad-description" }, [
                          _vm._v(
                            _vm._s(_vm.substitute(_vm.widgetData.description))
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      _vm._l(_vm.widgetData.links, function (link, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass: "launchpad-link btn btn-primary",
                            attrs: {
                              href: _vm.maybeAddScheme(
                                _vm.substitute(link.link_url)
                              ),
                              target:
                                _vm.isWeblink(
                                  _vm.maybeAddScheme(link.link_url)
                                ) && !_vm.isSelfUrl(link.link_url)
                                  ? `_blank`
                                  : ``,
                            },
                          },
                          [_vm._v(_vm._s(_vm.substitute(link.link_text)))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm.launchpadShow
                    ? _c("DashboardLaunchpadModal", {
                        attrs: {
                          widget: _vm.widget,
                          readOnly: !_vm.isOwner(_vm.widget),
                        },
                        on: {
                          cancel: _vm.modalCancel,
                          updated: _vm.launchpadUpdated,
                        },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }