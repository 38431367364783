<template>
  <WidgetFrame  @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      {{ substitute(widgetData.name) }}
    </template>
    <template v-slot:editor>
      <div :id="`LAUNCHPAD_WIDGET_BTN_ADD-${widget.uuId}`" v-on:click="onEdit" :class="{mobile: isTouchScreen}"><i class="far fa-pen-to-square"></i></div>
      <b-popover :target="`LAUNCHPAD_WIDGET_BTN_ADD-${widget.uuId}`" triggers="hover" placement="top" boundary="viewport">
        {{ $t('button.edit') }}
      </b-popover>
    </template>
    <template v-slot:content>
      <template v-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <div class="launchpad-container">
          <div v-if="widgetData.image" class="launchpad-header" :style="{'background-image': `url(${bannerUrl})` }"></div>
          <div v-if="widgetData.description" class="launchpad-description">{{ substitute(widgetData.description) }}</div>
          <div>
            <a v-for="(link, index) in widgetData.links"
               v-bind:key="index"
               class="launchpad-link btn btn-primary"
               :href='maybeAddScheme(substitute(link.link_url))'
               :target="isWeblink(maybeAddScheme(link.link_url)) && !isSelfUrl(link.link_url) ? `_blank` : ``">{{substitute(link.link_text)}}</a>
          </div>
        </div>
        <DashboardLaunchpadModal
          v-if="launchpadShow"
          :widget="widget"
          :readOnly="!isOwner(widget)"
          @cancel="modalCancel"
          @updated="launchpadUpdated"
        />
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import WidgetFrame from "@/components/Dashboard/WidgetFrame";
import { costFormat } from '@/helpers';
import { profileService } from '@/services';
import { convertDurationToDisplay, extractDurationConversionOpts } from '@/helpers/task-duration-process';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

export default {
  name: 'LaunchpadWidget',
  components: {
    WidgetFrame,
    DashboardLaunchpadModal: () => import('@/components/modal/DashboardLaunchpadModal.vue')
  },
  props: {
    widget: { type: Object, required: true, default: function() {return null} },
    project: { type: Object, required: false, default: function() {return null} },
  },
  watch: {
    widget(newWidget) {
      this.widgetData = this.widget;
      if (this.widget.originalUuid) {
        profileService.get(this.widget.originalUuid).then((response) =>  {
          const w = response.data[response.data.jobCase];
          if (w.length > 0) {
            this.widgetData = w[0];
          }
        })
        .catch(e => {
          this.widgetData = this.widget;
        }); 
      }
    }
  },
  computed: {
    isTouchScreen() {
      return window.matchMedia("(pointer: coarse)").matches
    },
    bannerUrl() {
      return `${this.baseUrl}api/file/${this.widget.image}`;
    },
  },
  data() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      launchpadShow: false,
      imageSelectorShow: false,
      baseUrl: process.env.BASE_URL,
      durationConversionOpts: {},
      userId: null,
      widgetData: null
    };
  },
  created() {
    this.getDurationConversionOpts();
    this.userId = this.$store.state.authentication.user.uuId;
    this.widgetData = this.widget;
    if (this.widget.originalUuid) {
      profileService.get(this.widget.originalUuid).then((response) =>  {
        const w = response.data[response.data.jobCase];
        if (w.length > 0) {
          this.widgetData = w[0];
        }
      })
      .catch(e => {
        this.widgetData = this.widget;
      }); 
    }
    this.loaded = true;
  },
  mounted() {
    
  },
  methods: {
    isOwner(widget) {
      return widget.owner === this.userId && !widget.originalUuid;
    },
    onOrientationChange({orientation, width, height}) {
      this.orientation = orientation;
      this.onResize({width, height});
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
    },
    modalCancel() {
      this.launchpadShow = false;
    },
    onEdit() {
      this.launchpadShow = true;
    },
    launchpadUpdated(widget) {
      this.$emit('launchpadUpdated', widget);
      this.launchpadShow = false;
    },
    substitute(text) {
      if (text) {
        return text.replaceAll(/%.+?%/g, this.mapper)
      }
      return text;
    },
    mapper(key) {
      switch(key) {
        case '%date%':
          return moment().local().format('L');
        case '%time%':
          return moment().local().format('HH:mm');
        case '%year%':
          return moment().local().format('YYYY');
        case '%month%':
          return moment().local().format('MM');
        case '%day%':
          return moment().local().format('DD');
        case '%dayofweek%':
          return moment().local().format('dddd');
        case "%USER.name%":
          return this.$store.state.authentication.user.name;
        case "%USER.firstName%":
          return this.$store.state.authentication.user.firstName;
        case "%USER.lastName%":
          return this.$store.state.authentication.user.lastName;
        case "%USER.nickName%":
          return this.$store.state.authentication.user.nickName;
        case "%USER.mobile%":
          return this.$store.state.authentication.user.mobile;
        case "%USER.email%":
          return this.$store.state.authentication.user.email;
        case "%USER.identifier%":
          return this.$store.state.authentication.user.identifier;
        case "%COMPANY.name%":
          return this.$store.state.company.name;
        case "%COMPANY.identifier%":
          return this.$store.state.company.identifier;
        case "%PROJECTAL.url%": 
          return window.location.host;
        default:
          if (this.project) {
            switch (key) {
              case "%PROJECT.name%":
                return this.project.name;
              case "%PROJECT.scheduleStart%":
                return moment.utc(this.project.scheduleStart).format('ll');
              case "%PROJECT.scheduleFinish%":
                return moment.utc(this.project.scheduleFinish).format('ll');
              case "%PROJECT.startTime%":
                return moment.utc(this.project.startTime).format('ll');
              case "%PROJECT.closeTime%":
                return moment.utc(this.project.closeTime).format('ll');
              case "%PROJECT.estimatedDuration%":
                return convertDurationToDisplay(this.project.estimatedDuration, 'D', this.durationConversionOpts);
              case "%PROJECT.actualDuration%":
                return convertDurationToDisplay(this.project.actualDuration, 'D', this.durationConversionOpts);
              case "%PROJECT.progress%":
                return this.project.progress*100 + '%';
              case "%PROJECT.plannedProgress%":
                return this.project.plannedProgress*100 + '%';
              case "%PROJECT.priority%":
                return this.project.priority ? this.project.priority : '';
              case "%PROJECT.description%":
                return this.project.description ? this.project.description : '';
              case "%PROJECT.estimatedTimeToComplete%":
                return convertDurationToDisplay(this.project.estimatedTimeToComplete, 'D', this.durationConversionOpts);
              case "%PROJECT.status%":
                return this.project.stage ? this.project.stage.name : '';
              case "%PROJECT.location%":
                return this.project.locationList.map(l => l.name).join(", ")
              case "%PROJECT.identifier%":
                return this.project.identifier;
              // Costs
              case "%PROJECT.actualCost%":
                return '$' + costFormat(this.project.actualCost);
              case "%PROJECT.actualCostNet%":
                return '$' + costFormat(this.project.actualCostNet);
              case "%PROJECT.estimatedCost%":
                return '$' + costFormat(this.project.estimatedCost);
              case "%PROJECT.estimatedCostNet%":
                return '$' + costFormat(this.project.estimatedCostNet);
              case "%PROJECT.fixedCost%":
                return '$' + costFormat(this.project.fixedCost);
              case "%PROJECT.fixedCostNet%":
                return '$' + costFormat(this.project.fixedCostNet);
              case "%PROJECT.plannedCost%":
                return '$' + costFormat(this.project.plannedCost);
              case "%PROJECT.plannedCostNet%":
                return '$' + costFormat(this.project.plannedCostNet);
              // Lists
              case "%PROJECT.customers%":
                return this.project.customerList.map(c => c.name).join(", ")
              case "%PROJECT.stages%":
                return this.project.stageList.map(s => s.name).join(", ")
              case "%PROJECT.rebates%":
                return this.project.rebateList.map(r => r.name).join(", ")
              case "%PROJECT.companies%":
                return this.project.companyList.map(c => c.name).join(", ")
            }
          }
          return key;
      }
    },
    isWeblink(link) {
      if (link) {
        return link.toLowerCase().startsWith('http');
      }
      return false;
    },
    maybeAddScheme(link) {
      if (link && /^[a-z0-9]+:/.test(link.toLowerCase())) {
        return link;
      } else {
        return "https://" + link;
      }
    },
    getDurationConversionOpts() {
      return this.$store.dispatch('data/configSchedule').then(value => {
        this.durationConversionOpts = extractDurationConversionOpts(value);
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    isSelfUrl(url) {
      return url.indexOf(window.location.host) !== -1 || url.indexOf('%PROJECTAL.url%') !== -1
    }
  }
}
</script>


<style lang="scss" scoped>
  .launchpad-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
  }
  .launchpad-header {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex: 1;
    min-height: 35%;
    margin-bottom: 10px;
  }
  .launchpad-description {
    color: var(--text-light);
    text-align: center;
    white-space: pre-line;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .launchpad-link {
    display: block !important;
    margin: 5px;
  }
</style>