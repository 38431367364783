var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.widget.name) + " ")]
        },
        proxy: true,
      },
      {
        key: "editor",
        fn: function () {
          return [
            _c(
              "div",
              {
                class: { mobile: _vm.isTouchScreen },
                attrs: { id: "DATAVIEW_WIDGET_BTN_ADD-" + _vm.widget.uuId },
                on: { click: _vm.onEdit },
              },
              [_c("i", { staticClass: "far fa-pen-to-square" })]
            ),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "DATAVIEW_WIDGET_BTN_ADD-" + _vm.widget.uuId,
                  triggers: "hover",
                  placement: "top",
                  boundary: "viewport",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : [
                  _c("div", { staticClass: "dataview-container" }, [
                    _vm.widget.image
                      ? _c("div", {
                          staticClass: "dataview-header",
                          style: {
                            "background-image": "url(" + _vm.bannerUrl + ")",
                          },
                        })
                      : _vm._e(),
                    _vm.widget.description
                      ? _c("div", { staticClass: "dataview-description" }, [
                          _vm._v(_vm._s(_vm.widget.description)),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("Dataview", {
                          attrs: {
                            isWidget: true,
                            dataviewId: _vm.widget.dataview.uuId,
                            widgetOwner: _vm.widget.owner,
                            dataviewPublic: _vm.widget.dataview.isPublic,
                            height: _vm.height - 100,
                            width: _vm.width,
                            dataviewComponent: _vm.widget.dataview.id,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.dataviewShow
                    ? _c("DashboardDataviewModal", {
                        attrs: { widget: _vm.widget },
                        on: {
                          ok: _vm.dataviewUpdated,
                          cancel: _vm.modalCancel,
                        },
                      })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }